import InputValidator from "scripts/partials/InputValidator.js";


export default function RecoveryForm(formElement, query) {
    var $ = query;

    formElement.setAttribute("novalidate", true);
    const url = formElement.action;
    const inputEmail = formElement.querySelector(".js-recovery-form-input");
    const textEmail = document.querySelector(".js-recovery-block-email");

    const inputsValidating = formElement.querySelectorAll(".js-recovery-form-input");
    const inputsValidators = [];

    for (let i = 0, len = inputsValidating.length; i < len; i++) {
        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

        const inputValidator = new InputValidator({
            "domElement": inputsValidating[i],
            "requiredFlag": true,
            "errorClass": inputInvalidClass
        });
        inputsValidators.push(inputValidator);
    }

    formElement.addEventListener("submit", function(e) {
        
        const isValid = validateForm(inputsValidators);
        //e.preventDefault();
        console.log("1");
        if (!isValid) {
            e.preventDefault();
            console.log("2");
        } else {
            e.preventDefault();
            console.log("3");
            requestRecovery();
        }
    });


    function requestRecovery(){

        //console.log("dfdfdsfsdf", $(formElement));

        $.ajax({
            type: "POST",
            url: url,
            dataType: "json",
            data: $(formElement).serialize(),
            success: function(data) {
                //console.log('success');
                if (!data.errors) {
                    //console.log('yes');
                    recoveryPassword();
                } else {
                    //console.log('no');
                    formElement.setAttribute("novalidate", true);

                    for (let i = 0, len = inputsValidating.length; i < len; i++) {
                        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");
                        inputsValidating[i].classList.add(inputInvalidClass);
                        
                    }

                    
                }
            },
            error:  function(){
                console.log("fail");
            }
        });
    }

    function  recoveryPassword(){

        //let modalBox = document.getElementById("modal-box-recovery");
        let valueEmail = inputEmail.value;
        textEmail.innerText = valueEmail;
        formElement.reset();
        formElement.classList.add("is-success");
        //let accountBtn = document.querySelector(".js-header-account-button");
        //let accountLogin = document.querySelector(".js-header-account-login");

        //modalBox.classList.remove("is-show");
        //document.body.classList.remove("no-scrolling");
        //window.location.reload();
        //accountBtn.classList.add("is-hidden");
        //accountLogin.classList.add("is-show");
        
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    /*function setTextInputsValid(inputsValidators) {
        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            inputsValidators[i].setValid();
        }
    }*/

    function validateForm(inputsValidators) {
        const isValid = validateTextInputs(inputsValidators);
        return isValid;
    }
}