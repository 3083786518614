
export default function PerformerList(item) {
    let itemList = item;
    let scrollTo = document.querySelector(".js-chat");

    itemList.addEventListener("click", function(){
        let itemDataId = this.getAttribute("data-id");
        document.getElementById(itemDataId).click();

        window.scrollTo({
            top: calculate_scroll_end(scrollTo, 140),
            left: 0,
            behavior: "smooth"
        });

        function calculate_scroll_end (scrollTo, indent) {
            let header = document.getElementsByClassName("header")[0];
            let coordinateY = scrollTo.getBoundingClientRect().top;
            let currentScroll = window.scrollY;
            let heightHeader = indent ? indent : header.offsetHeight;
            let heightScroll = coordinateY - heightHeader + currentScroll;

            return heightScroll;
        }
    })

}