
export default function ResultForm(formElement, query) {
    var $ = query;

    let maxFileSize = 20 * 1024 * 1024;
    let maxFilesCount = 10;

    let filesGroup = [];
    let addressList =[];
    let index = 0;

    let AddFileButton = formElement.getElementsByClassName("js-result-form-input-files")[0];
    let fileList = document.getElementById("result-form-files-list");
    let fileItem = fileList.getElementsByClassName("js-file-item");
    let resultWrapper = document.getElementById("order-result-wrapper");
    let bodyWrapper = document.body;
    
    let itemPreviewTemplate = fileList.removeChild(fileList.getElementsByClassName("js-file-item")[0]);

    formElement.setAttribute("novalidate", true);
    const url = formElement.action;

    addChange();

    formElement.addEventListener("submit", function(e) {

        e.preventDefault();
        var isValid = validateForm(this);

        if (!isValid) {
            e.preventDefault();
            console.log("no");
        } else {
            requestResult();
        }
    });

    function requestResult(){
		let formData = new FormData(formElement);
		console.log("request");
        $.ajax({
            type: "POST",
            url: url,
			cache: false,
			contentType: false,
			processData: false,
            data: formData,
            dataType: "json",
            success: function(data) {
                console.log("success");
                if (data.resultBody) {
                    let orderResult = resultWrapper.querySelector(".order-result");
                    $(orderResult).remove();
                    $(resultWrapper).append(data.resultBody);
                }
				if (data.reload) {
					window.location.reload();
				}
                if (data.result) {
                    console.log("5666");
                    formElement && closeModalBox(formElement);
				}
            },
            error:  function(){
                console.log("fail");
            }
        });
    }

    function closeModalBox(form) {
        let modalBox = false;

        if(form.closest(".js-modal-box")){
            modalBox = form.closest(".js-modal-box");
        }

        if(modalBox){
            modalBox.classList.remove("is-show");
            bodyWrapper.classList.remove("no-scrolling");
        }
    }

    function validateForm(form) {
        let isValid = true;

        var resultForm = form;
        var cloudField = resultForm.querySelector(".js-result-form-input");

        var valueCloud = cloudField.value;
        //console.log("555", AddFileButton.files, filesGroup.length, addressList);

        if (!(valueCloud === "") || fileItem.length > 0) {
            isValid = true;
            if(resultForm.classList.contains("is-invalid")){
                resultForm.classList.remove("is-invalid")
            }
        } else {
            isValid = false;

            if(!resultForm.classList.contains("is-invalid")){
                resultForm.classList.add("is-invalid")
            }
        }

        console.log("valueDate3333", isValid);
        return isValid;
    }


    

    function addChange() {
        AddFileButton.addEventListener("change", houmFunction);
    }

    function houmFunction() {
        let filesCurrent = this.files;

        counterFile(filesCurrent);
        inspectorARR();
        inspectorHTML();
    }

    function counterFile(filesGroupCurrent) {
        let filesGroupLength = filesGroup.length;

        for (let counter = 0; counter < filesGroupCurrent.length; counter++) {
            filesGroup[filesGroupLength + counter] = filesGroupCurrent[counter];
            addressList[filesGroupLength + counter] = index;

            index++;
        }
    }

    function inspectorARR() {
        let fullSize = 0;

        for (let counter = 0; counter < filesGroup.length; counter++) {
            fullSize += filesGroup[counter].size;

            if ((fullSize > maxFileSize) || (counter >= maxFilesCount)) {
                filesGroup.splice(counter);
                addressList.splice(counter);
                break;
            }
        }
    }

    function inspectorHTML() {
        let currentElements = fileList.getElementsByClassName("js-file-item");

        for (let counter = currentElements.length; counter < filesGroup.length; counter++) {

            addHTML(counter);
        }
    }

    function addHTML(counter) {
        let currentFile = filesGroup[counter];
        let itemPreview = itemPreviewTemplate.cloneNode(true);
        let text = currentFile.name;
        let sizeFile = (currentFile.size/1024).toFixed(1) + " KB";
        console.log("dfsdf", currentFile);


        itemPreview.getElementsByClassName("js-file-name")[0].text = text;
        itemPreview.getElementsByClassName("js-file-size")[0].append(sizeFile);

        fileList.append(itemPreview);

        itemPreview.addEventListener("click", function() {
                let currentCounter = addressList.indexOf(counter);

                itemPreview.remove();
                filesGroup.splice(currentCounter, currentCounter);
                addressList.splice(currentCounter, currentCounter);
            }
        );
    }

}    