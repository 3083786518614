import OrderValidationForm from "./OrderValidationForm.js";

export default function OrderServices(formElement, query) {
    var $ = query;

    let maxFileSize = 20 * 1024 * 1024;
    let maxFilesCount = 10;

    let filesGroup = [];
    let addressList =[];
    let index = 0;

    let AddFileButton = formElement.getElementsByClassName("js-order-services-input-files")[0];
    let fileList = document.getElementById("order-services-list-files");
    let checkTechnicalTask = formElement.getElementsByClassName("js-order-services-technical-task")[0];
    let technicalTaskWrapper = formElement.getElementsByClassName("order-services__files-wrapper_theme_technical-task")[0];
    let noTechnicalFieldWrapper = formElement.getElementsByClassName("js-order-services-no-technical-wrapper")[0];
    //console.log("listFiles", fileList);
    let itemPreviewTemplate = fileList.removeChild(fileList.getElementsByClassName("js-order-services-item")[0]);


    let editFileList = formElement.getElementsByClassName("js-order-services-add-files")[0].value;
    let deleteFiles = formElement.getElementsByClassName("js-order-services-del-files")[0];
    let arrDeleteFiles = [];

    if(editFileList !== ""){
       
       window.addEventListener("load", function () {
            editFiles();
      })
      
    }

    addChange();
    isTechnicalTask();
    OrderValidationForm(formElement, $);

    function isTechnicalTask() {
        checkTechnicalTask.addEventListener("change", function(){
            console.log("sdadads", technicalTaskWrapper);
            let selectListNoTechnicalTask = formElement.getElementsByClassName("js-no-valid-select");
            let inputListNoTechnicalTask = formElement.getElementsByClassName("js-no-valid-input");

            if(this.checked){

                if(!technicalTaskWrapper.classList.contains("is-not-active")){
                    technicalTaskWrapper.classList.add("is-not-active")
                }
                if(!noTechnicalFieldWrapper.classList.contains("is-show")){
                    noTechnicalFieldWrapper.classList.add("is-show")
                }

                for (let i = 0; i < selectListNoTechnicalTask.length; i++) {
                    let selectNoValid = selectListNoTechnicalTask[i];

                    if(!selectNoValid.classList.contains("js-order-services-select")){
                        selectNoValid.classList.add("js-order-services-select");

                        selectNoValid.setAttribute("required", "required");
                    }

                    selectNoValid.options[selectNoValid.selectedIndex].value = "0";

                }

                for (let i = 0; i < inputListNoTechnicalTask.length; i++) {
                    let inputNoValid = inputListNoTechnicalTask[i];

                    if(!inputNoValid.classList.contains("js-order-services-form-input")){
                        inputNoValid.classList.add("js-order-services-form-input");

                        inputNoValid.setAttribute("required", "required");
                    }


                }

            } else {
                if(technicalTaskWrapper.classList.contains("is-not-active")){
                    technicalTaskWrapper.classList.remove("is-not-active")
                }
                if(noTechnicalFieldWrapper.classList.contains("is-show")){
                    noTechnicalFieldWrapper.classList.remove("is-show")
                }

                for (let i = 0; i < selectListNoTechnicalTask.length; i++) {
                    let selectNoValid = selectListNoTechnicalTask[i];
                    //let selectNoValidBlock = selectNoValid.closest(".js-order-services-select-block");

                    /*if(selectNoValid.classList.contains("js-order-services-select")){
                        selectNoValid.classList.remove("js-order-services-select");


                    }*/

                    selectNoValid.removeAttribute("required");

                    selectNoValid.options[selectNoValid.selectedIndex].value = "150";

                    /*if(selectNoValidBlock.classList.contains("is-invalid")){
                        selectNoValidBlock.classList.remove("is-invalid");
                    }*/
                }

                for (let i = 0; i < inputListNoTechnicalTask.length; i++) {
                    let inputNoValid = inputListNoTechnicalTask[i];
                    let inputInvalidClass = inputNoValid.getAttribute("data-invalid-class");

                    if(inputNoValid.classList.contains("js-order-services-form-input")){
                        inputNoValid.classList.remove("js-order-services-form-input");

                        inputNoValid.removeAttribute("required");
                    }

                    if(inputNoValid.classList.contains(inputInvalidClass)){
                        inputNoValid.classList.remove(inputInvalidClass);
                    }
                    console.log("inval", inputInvalidClass);
                }
            }
        })
    }

    function addChange() {
        AddFileButton.addEventListener("change", houmFunction);
    }

    function houmFunction() {
        let filesCurrent = this.files;

        counterFile(filesCurrent);
        inspectorARR();
        inspectorHTML();
    }

    function counterFile(filesGroupCurrent) {
        let filesGroupLength = filesGroup.length;

        for (let counter = 0; counter < filesGroupCurrent.length; counter++) {
            filesGroup[filesGroupLength + counter] = filesGroupCurrent[counter];
            addressList[filesGroupLength + counter] = index;

            index++;
        }
    }

    function inspectorARR() {
        let fullSize = 0;

        for (let counter = 0; counter < filesGroup.length; counter++) {
            fullSize += filesGroup[counter].size;

            if ((fullSize > maxFileSize) || (counter >= maxFilesCount)) {
                filesGroup.splice(counter);
                addressList.splice(counter);
                break;
            }
        }
    }

    function inspectorHTML() {
        let currentElements = fileList.getElementsByClassName("js-order-services-item");

        for (let counter = currentElements.length; counter < filesGroup.length; counter++) {

            addHTML(counter);
        }
    }

    function addHTML(counter) {
        let currentFile = filesGroup[counter];
        let itemPreview = itemPreviewTemplate.cloneNode(true);
        let text = currentFile.name;
        let sizeFile = (currentFile.size/1024).toFixed(1) + " KB";
        console.log("dfsdf", currentFile);


        itemPreview.getElementsByClassName("js-order-services-file-name")[0].text = text;
        itemPreview.getElementsByClassName("js-order-services-file-size")[0].append(sizeFile);

        fileList.append(itemPreview);

        /*itemPreview.addEventListener("click", function() {
                let currentCounter = addressList.indexOf(counter);

                itemPreview.remove();
                filesGroup.splice(currentCounter, currentCounter);
                addressList.splice(currentCounter, currentCounter);
            }
        );*/

        itemPreview.addEventListener("click", function(e) {
            let targetElement = e.target;
            let currentCounter = addressList.indexOf(counter);

            if(targetElement.closest(".js-order-services-file-delete")){
                itemPreview.remove();
                filesGroup.splice(currentCounter, currentCounter);
                addressList.splice(currentCounter, currentCounter);
                //console.log("3333", filesGroup, addressList);
            }
        });
    }

    function editFiles(){
        
        let arrFiles = JSON.parse(editFileList);
        
        console.log("massive", arrFiles, arrFiles.length);
        for(let i = 0; i < arrFiles.length; i++){

            let currentFile = arrFiles[i];
            let itemPreview = itemPreviewTemplate.cloneNode(true);
            let text = currentFile.title;
            let urlFile = currentFile.url;
            let sizeFile = currentFile.size + " KB";
            itemPreview.getElementsByClassName("js-order-services-file-name")[0].text = text;
            itemPreview.getElementsByClassName("js-order-services-file-name")[0].setAttribute("href", urlFile);
            itemPreview.getElementsByClassName("js-order-services-file-size")[0].append(sizeFile);

            fileList.append(itemPreview);

            itemPreview.addEventListener("click", function(e) {
                let targetElement = e.target;
                //let currentCounter = addressList.indexOf(counter);
    
                if(targetElement.closest(".js-order-services-file-delete")){
                    itemPreview.remove();
                    arrDeleteFiles.push(currentFile);
                    deleteFiles.value = JSON.stringify(arrDeleteFiles);
                    //console.log("3333", filesGroup, addressList);
                }
            });  
        }
        console.log("delete", arrDeleteFiles);
    }

    //console.log("massive", arrFiles, arrFiles.length);


    /*let inputFile = formElement.querySelector(".js-order-services-input-file");

    var maxFileSize = 2 * 1024 * 1024; // (байт) Максимальный размер файла (2мб)
    var queue = {};
    var fileList = $("#order-services-list-files");
    // 'detach' подобно 'clone + remove'
    var itemPreviewTemplate = fileList.find(".js-order-services-item").detach();
    // Вычисление лимита
    function limitUpload(files) {
        var count;
        var limit = 10;
        if (files.length < limit) {
            count = files.length;
        }
        else {
            count = limit;
        }
        return count;
    }
    $(inputFile).on("change", function() {
        var files = this.files;

        // Перебор файлов до лимита
        for (var i = 0; i < limitUpload(files); i++) {
            //console.log(files[i]);
            var file = files[i];
            if (file.size > maxFileSize) {
                alert('Размер не должен превышать 2 Мб');
                continue;
            }
            preview(files[i]);
        }
        this.value = '';
    });

    function preview(file) {
        var reader = new FileReader();
        reader.addEventListener('load', function(event) {
            var itemPreview = itemPreviewTemplate.clone();
            itemPreview.find(".js-order-services-file-name")[0].text = file.name;
            let sizeFile = (file.size/1024).toFixed(1) + " KB";
            itemPreview.find(".js-order-services-file-size")[0].append(sizeFile);

            //console.log("sdfsdffs", file.size, sizeFile);
            
            fileList.append(itemPreview);
        
            // Обработчик удаления
            itemPreview.on('click', function() {
                delete queue[file.name];
                $(this).remove();
            });
            queue[file.name] = file;
        });
        reader.readAsDataURL(file);
    }*/

}    