import InputValidator from "scripts/partials/InputValidator.js";
//import Choices from "choices.js";

export default function OrderValidationForm(formElement, query) {
    var $ = query;

    formElement.setAttribute("novalidate", true);
    const url = formElement.action;
    const modalBoxNoticeSuccess = document.getElementById("modal-box-order-success");

    const inputEmail = formElement.querySelector("#input-order-services-email");
    const selectList = formElement.getElementsByClassName("js-order-services-select");
    const selectPeriod = formElement.querySelector(".js-order-services-time-select-block");
    const inputFileTechnicalTask = formElement.querySelector(".js-order-services-input-file");
    const inputFileWrapper = formElement.querySelector(".js-label-file");
    const inputFiles = formElement.querySelector(".js-order-services-input-files");
    const textareaList = formElement.querySelectorAll(".js-textarea-autosize");
    const checkTechnicalTask = formElement.querySelector(".js-order-services-technical-task");
    const fileList = document.getElementById("order-services-list-files");
    const fileTechnicalTaskList = document.getElementById("order-services-list-file");

    let dateList;

    //let inputsValidating = formElement.querySelectorAll(".js-order-services-form-input");
    const inputsValidators = [];



    /*for (let i = 0, len = inputsValidating.length; i < len; i++) {
        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

        const inputValidator = new InputValidator({
            "domElement": inputsValidating[i],
            "requiredFlag": true,
            "errorClass": inputInvalidClass
        });
        inputsValidators.push(inputValidator);
    }*/

    formElement.addEventListener("submit", function(e) {
        let inputsValidating = formElement.querySelectorAll(".js-order-services-form-input");
        for (let i = 0, len = inputsValidating.length; i < len; i++) {
            const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

            const inputValidator = new InputValidator({
                "domElement": inputsValidating[i],
                "requiredFlag": true,
                "errorClass": inputInvalidClass
            });
            inputsValidators.push(inputValidator);
        }

        dateList = formElement.getElementsByClassName("js-order-services-form-date");
        console.log("wwwr", dateList);

        const isValid = validateForm(inputsValidators);



        e.preventDefault();
        //this.classList.add("is-registered");
        //console.log("sdfwefewrwer", isValid);
        if (!isValid) {
            e.preventDefault();
            //console.log("sdfwefewrwer111111");
        } else {
            requestRegistration();

        }
    });

    function requestRegistration(){
		var formData = new FormData(formElement);
        $.ajax({
            type: "POST",
            url: url,
			cache: false,
			contentType: false,
			processData: false,
            data: formData,
            success: function(data) {
                console.log("success");
                if (!data.errors) {
                    console.log("yes");
					if (data.location) {
						window.location = data.location;
					}
                    //window.location.reload();
                    //formElement.classList.add("is-accept");
                    
                    clearForm();    
                    modalBoxNoticeSuccess.classList.add("is-show");
                } else {
                    console.log("no");
                    formElement.setAttribute("novalidate", true);

                    const inputInvalidClass = inputEmail.getAttribute("data-invalid-class");
                    inputEmail.classList.add(inputInvalidClass);
                }
            },
            error:  function(){
                console.log("fail");
            }
        });
    }

    function clearForm(){
        //formElement.setAttribute("novalidate", false);
        formElement.reset();

        for (let i = 0, len = selectList.length; i < len; i++) {
            let selectItem = selectList[i];

            //console.log("ctktrn", selectItem);
            let remove = selectItem.closest(".js-select").getElementsByClassName("select__remove-item-button")[0];
            //console.log("ssdsssss", remove );
            remove.click();
            //selectItem.clearChoices();
        }

        let removePeriod = selectPeriod.getElementsByClassName("select__remove-item-button")[0];
        removePeriod.click();

        for (const prop of Object.getOwnPropertyNames(inputFileTechnicalTask.files)) {
            delete inputFileTechnicalTask.files[prop];
        }

        for (const prop of Object.getOwnPropertyNames(inputFiles.files)) {
            delete inputFiles.files[prop];
        }

        removeChildren(fileList);
        removeChildren(fileTechnicalTaskList);
        
        for (let i = 0, len = textareaList.length; i < len; i++) {
            let textarea = textareaList[i];

            textarea.style.height = "200px";
        }

        if(inputFileWrapper.classList.contains("is-hidden")){
            inputFileWrapper.classList.remove("is-hidden");
            console.log(checkTechnicalTask);
            checkTechnicalTask.removeAttribute("disabled");
        }
    }

    function removeChildren(elem) {
        while (elem.lastChild) {
          elem.removeChild(elem.lastChild);
        }
    }

    function validateSelect(selectList) {
        let isValid = true;
        for (let i = 0, len = selectList.length; i < len; i++) {
            const isFormElementValid = searchValueSelect(selectList[i]);

            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    function searchValueSelect(select) {
        let valueSelect = select.options[select.selectedIndex].value;
        let selectBlock = select.closest(".js-order-services-select-block");
        let isValid;

        console.log("555555", valueSelect);

        if(valueSelect === "0") {
            if(!selectBlock.classList.contains("is-invalid")){
                selectBlock.classList.add("is-invalid");
            }
            isValid = false;
        } else {
            if(selectBlock.classList.contains("is-invalid")){
                selectBlock.classList.remove("is-invalid");
            }
            isValid = true;
        }

        return isValid;
    }

    function validateDate(dateList) {
        let isValid = true;
        for (let i = 0, len = dateList.length; i < len; i++) {
            const isFormElementValid = searchValueDate(dateList[i]);
            console.log("valueDate0000", isFormElementValid);

            if (!isFormElementValid) {
                isValid = false;
            }
        }
        console.log("valueDate2222", isValid);
        return isValid;
    }

    function searchValueDate(date) {
        let valueDate = date.value;
        let dateBlock = date.closest(".datetime-field");
        let isValid;

        //console.log("valueDate", valueDate);

        if(valueDate === "") {
            if(!dateBlock.classList.contains("is-invalid")){
                dateBlock.classList.add("is-invalid");
            }
            if(dateBlock.classList.contains("is-field")){
                dateBlock.classList.remove("is-field");
            }
            isValid = false;
        } else {
            if(dateBlock.classList.contains("is-invalid")){
                dateBlock.classList.remove("is-invalid");
            }
            if(!dateBlock.classList.contains("is-field")){
                dateBlock.classList.add("is-field");
            }
            isValid = true;
        }
        console.log("bnju lfnt", isValid);
        return isValid;
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    function validateTechnicalTask(check, inputFile){
        let isValid = true;

        console.log("32123233", inputFile.files.length);
        let counterFiles = inputFile.files.length;
        if(counterFiles > 0){

            if(inputFile.classList.contains("is-invalid")){
                inputFile.classList.remove("is-invalid")
            }

            isValid = true;
        } else {
            if(check.checked){
                if(inputFile.classList.contains("is-invalid")){
                    inputFile.classList.remove("is-invalid")
                }

                isValid = true;
            } else {
                if(!inputFile.classList.contains("is-invalid")){
                    inputFile.classList.add("is-invalid")
                }

                isValid = false;
            }
        }
        console.log("kjlkjk", isValid);

        return isValid;
    }

    /*function setTextInputsValid(inputsValidators) {
        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            inputsValidators[i].setValid();
        }
    }*/

    function validateForm(inputsValidators) {
        let isValid = true;

        const isTextInputsValid = validateTextInputs(inputsValidators);

        if (!isTextInputsValid) {
            isValid = isTextInputsValid;
        }

        const isSelectValid = validateSelect(selectList);

        if (!isSelectValid) {
            isValid = isSelectValid;
        }

        const isDateValid = validateDate(dateList);

        if (!isDateValid) {
            isValid = isDateValid;
        }

        const isTechnicalTaskValid = validateTechnicalTask(checkTechnicalTask, inputFileTechnicalTask);

        if (!isTechnicalTaskValid) {
            isValid = isTechnicalTaskValid;
        }
        console.log("valueDate3333", isValid);
        return isValid;
    }

}