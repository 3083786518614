import DatetimeForm from "../datetime-form/DatetimeForm.js";
import PriceForm from "../price-form/PriceForm.js";

export default function Chat(container, query) {
    var $ = query;
    let chatForm = container;

    let maxFileSize = 20 * 1024 * 1024;
    let maxFilesCount = 10;

    let filesGroup = [];
    let addressList =[];
    let index = 0;
    var fromtime = 0;

    let AddFileButton = chatForm.getElementsByClassName("js-chat-input-files")[0];
    let fileList = document.getElementById("chat-attached-files");
    let fileItem = fileList.getElementsByClassName("js-chat-attached-file");
    var chatList = chatForm.closest(".chat").querySelector(".js-chat-body");
    var urlapi = chatForm.action;
    var scrf = $("input[name=_token]", chatForm).val();

    let statusListWrapper = document.querySelector(".js-status-list-wrapper");

    let modalBoxDatetime = document.getElementById("modal-box-datetime");
    let modalBoxPrice = document.getElementById("modal-box-price");
    let bodyWrapper =document.body;

    //console.log("add", AddFileButton, chatForm);

    if(statusListWrapper) {
        
        statusListWrapper.addEventListener("click", function(e){
            let targetElement  = e.target;
    
            //console.log("6666", targetElement);
    
            if(targetElement.closest(".js-modal-box-datetime-open")){
                modalBoxDatetime.classList.add("is-show");
                bodyWrapper.classList.add("no-scrolling");
                let form = modalBoxDatetime.querySelector(".js-datepicker-form");
                let statusItem = targetElement.closest(".js-modal-box-datetime-open");
                let statusId = $(statusItem).attr("status-id");
    
                DatetimeForm(form, requestChat, {sendstatus:1, status:statusId, loadmsg:1});
            }
    
            if(targetElement.closest(".js-modal-box-price-open")){
                modalBoxPrice.classList.add("is-show");
                bodyWrapper.classList.add("no-scrolling");
    
                let form = modalBoxPrice.querySelector(".js-price-form");
                let statusItem = targetElement.closest(".js-modal-box-price-open");
                let statusId = $(statusItem).attr("status-id");
    
                PriceForm(form, requestChat, {sendstatus:1, status:statusId, loadmsg:1});
            }
    
            if(targetElement.closest(".js-status-list-item")){
                let statusItem = targetElement.closest(".js-status-list-item");
                let statusId = $(statusItem).attr("status-id");
                console.log(statusItem, statusId);
                requestChat(null, { sendstatus:1, status: statusId, loadmsg:1 })
            }
    
        });
    }


    let itemPreviewTemplate = fileList.removeChild(fileItem[0]);

    addChange();
    
    //OrderValidationForm(formElement, $);


    function addChange() {
        AddFileButton.addEventListener("change", houmFunction);
    }

    function houmFunction() {
        let filesCurrent = this.files;

        counterFile(filesCurrent);
        inspectorARR();
        inspectorHTML();
    }

    function counterFile(filesGroupCurrent) {
        let filesGroupLength = filesGroup.length;

        for (let counter = 0; counter < filesGroupCurrent.length; counter++) {
            filesGroup[filesGroupLength + counter] = filesGroupCurrent[counter];
            addressList[filesGroupLength + counter] = index;

            index++;
        }
    }

    function inspectorARR() {
        let fullSize = 0;

        for (let counter = 0; counter < filesGroup.length; counter++) {
            fullSize += filesGroup[counter].size;

            if ((fullSize > maxFileSize) || (counter >= maxFilesCount)) {
                filesGroup.splice(counter);
                addressList.splice(counter);
                break;
            }
        }
    }

    function inspectorHTML() {
        let currentElements = fileList.getElementsByClassName("js-chat-attached-file");

        for (let counter = currentElements.length; counter < filesGroup.length; counter++) {

            addHTML(counter);
        }
    }

    function addHTML(counter) {
        let currentFile = filesGroup[counter];
        let itemPreview = itemPreviewTemplate.cloneNode(true);
        let text = currentFile.name;
        let sizeFile = (currentFile.size/1024).toFixed(1) + " KB";


        itemPreview.getElementsByClassName("js-chat-attached-file-name")[0].text = text;
        itemPreview.getElementsByClassName("js-chat-attached-file-size")[0].append(sizeFile);
        
        fileList.append(itemPreview);

        itemPreview.addEventListener("click", function(e) {
                let targetElement = e.target;
                let currentCounter = addressList.indexOf(counter);

                if(targetElement.closest(".js-chat-file-delete")){
                    itemPreview.remove();
                    filesGroup.splice(currentCounter, currentCounter);
                    addressList.splice(currentCounter, currentCounter);
                    console.log("3333", filesGroup, addressList);
                }
            }
        );
    }

    chatForm.addEventListener("submit", function (e) {
        e.preventDefault();
        var isValid = validateChat(this);

        if (!isValid) {
            e.preventDefault();
            console.log("no");
        } else {
            requestChat(this, {sendmsg:1});
        }
    });

    function validateChat(chat) {
        var chatForm = chat;
        var isValid = true;
        var messageField = chatForm.querySelector(".js-chat-textarea-autosize");

        var valueMessage = messageField.value;
        //console.log("555", AddFileButton.files, filesGroup.length, addressList);

        if (!(valueMessage === "") || fileItem.length > 0) {
            isValid = true;
        } else {
            isValid = false;
        }

        return isValid;
    }

    function requestChat(form, vars) {
        var formElement = form;
		var formData = formElement ? new FormData(formElement) : new FormData();
		vars._token = scrf;
		formData.append("fromtime", fromtime);
		if (vars) for (var i in vars) formData.append(i, vars[i]);
        console.log("yes");

        $.ajax({
            type: "POST",
            url: urlapi,
            data: formData,  //$(formElement).serialize()
            dataType: "json",
            cache: false,
            contentType: false,
            processData: false,

            success: function success(data) {
                console.log("запрос выполнен");
				if (data.fromtime) {
					fromtime = data.fromtime;
				}
                if (data.message) {
                    $(chatList).append(data.message);
                }
                if (data.list) {
                    let statusList = statusListWrapper.querySelector(".status-list");
                    $(statusList).remove();
                    $(statusListWrapper).append(data.list);
                }
				if (data.result) {
                    formElement && clearForm(formElement);
                    formElement && closeModalBox(formElement);
				}
				if (data.orderStatus) {
					if ( (data.orderStatus >= 20) && (data.isowner === "0") ) {
						$("#order-result-wrapper").removeClass("is-hidden");
					}
				}
            }
        });
    }

	function tick() {
		requestChat(null, {loadmsg:1});
		//setTimeout(tick, 10000); //-  убрала таймаут
	}
	tick();
	

	$(chatList).on("click", ".js-chat-status-confirm", function() {
        var msgid = $(this).data("msgid");
        $(this).closest(".chat__message").addClass("is-active");
		$(this).closest(".chat__message").remove();
		requestChat(null, {msgid: msgid, confirm: 1, loadmsg: 1 });
	});
	$(chatList).on("click", ".js-chat-status-cancel", function() {
        var msgid = $(this).data("msgid");
        $(this).closest(".chat__message").addClass("is-canceled");
		$(this).closest(".chat__message").remove();
		requestChat(null, {msgid: msgid, cancel: 1, loadmsg: 1 });
	});

    function clearForm(formElement) {
        //console.log("clear");
        formElement.reset();
        //AddFileButton.files.length = 0;
        if (formElement.classList.contains("js-chat-form")) {
            filesGroup.length = 0;
            addressList.length = 0;
            index = 0;
            for (const prop of Object.getOwnPropertyNames(AddFileButton.files)) {
                delete AddFileButton.files[prop];
            }

            removeChildren(fileList);
            chatForm.querySelector(".js-chat-textarea-autosize").style.height = "30px";
        }
        
        //console.log("asasasasas", AddFileButton.files);
    }

    function removeChildren(elem) {
        while (elem.lastChild) {
          elem.removeChild(elem.lastChild);
        }
    }

    function closeModalBox(form) {
        let modalBox = false;

        if(form.closest(".js-modal-box")){
            modalBox = form.closest(".js-modal-box");
        }

        if(modalBox){
            modalBox.classList.remove("is-show");
            bodyWrapper.classList.remove("no-scrolling");
        }
    }
}