// import maskInput from "vanilla-text-mask";
import InputValidator from "scripts/partials/InputValidator.js";

export default function RegistrationForm(formElement, query) {
    var $ = query;


    formElement.setAttribute("novalidate", true);
    const url = formElement.action;
    //const inputPhone = formElement.querySelector(".js-registration-form-input-tel");
    const visibilityPassword = formElement.querySelector(".js-visibility-password-btn");
    const inputsPassword = formElement.getElementsByClassName("js-registration-form-input-password");
    const inputPassword = formElement.querySelector("#input-registration-password");
    const inputRepeatPassword = formElement.querySelector("#input-registration-repeat-password");
    const inputEmail = formElement.querySelector("#input-registration-email");
    
    // const inputPhonePlaceholderBasic = inputPhone.getAttribute("placeholder");
    // const inputPhonePlaceholderFocus = inputPhone.getAttribute("data-placeholder-focus");
    //const inputPhoneInvalidClass = inputPhone.getAttribute("data-invalid-class");
    // const phoneMask = ["+", /[1-9]/, " ", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/];
    // let isInputPhoneValid;
    /*const inputPhoneValidator = new InputValidator({
        "domElement": inputPhone,
        "requiredFlag": true,
        "errorClass": inputPhoneInvalidClass
    });*/
    const inputsValidating = formElement.querySelectorAll(".js-registration-form-input");
    const inputsValidators = [];

    // inputPhone.addEventListener("focus", function() {
    //     inputPhone.placeholder = inputPhonePlaceholderFocus;
    // });

    // inputPhone.addEventListener("blur", function() {
    //     inputPhone.placeholder = inputPhonePlaceholderBasic;
    // });


    // const inputPhoneMaskController = maskInput({
    //     inputElement: inputPhone,
    //     mask: phoneMask
    // });

    // inputPhone.addEventListener("input", function() {
    //     isInputPhoneValid = validatePhoneInput(inputPhone);
    // });

    visibilityPassword.addEventListener("click", function(){

        for (let i = 0, len = inputsPassword.length; i < len; i++) {
            const inputPassword = inputsPassword[i];
    
            if (inputPassword.getAttribute("type") === "password") {
                inputPassword.setAttribute("type", "text");
                this.classList.add("is-show");
            } else {
                inputPassword.setAttribute("type", "password");
                this.classList.remove("is-show");
            }
        }
    });

    for (let i = 0, len = inputsValidating.length; i < len; i++) {
        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

        const inputValidator = new InputValidator({
            "domElement": inputsValidating[i],
            "requiredFlag": true,
            "errorClass": inputInvalidClass
        });
        inputsValidators.push(inputValidator);
    }

    formElement.addEventListener("submit", function(e) {
        const isValid = validateForm(inputsValidators);
        e.preventDefault();
        //this.classList.add("is-registered");
        console.log("sdfwefewrwer", isValid);
        if (!isValid) {
            e.preventDefault();
            console.log("sdfwefewrwer111111");
        } else {
            requestRegistration();
            
        }
    });

    function requestRegistration(){
        $.ajax({
            type: "POST",
            url: url,
            dataType: "json",
            data: $(formElement).serialize(),
            success: function(data) {
                console.log("success");
                if (data.registered) {
                    console.log("yes");
                    window.location.reload();
                    //formElement.classList.add("is-registered");
                } else {
                    console.log("no");
                    formElement.setAttribute("novalidate", true);

                    const inputInvalidClass = inputEmail.getAttribute("data-invalid-class");
                    inputEmail.classList.add(inputInvalidClass);
                }
            },
            error:  function(){
                console.log("fail");
            }
        });
    }


    /*function validatePhoneInput(inputPhone) {
        let isValid;

        // const unmaskedValue = inputPhone.value.replace(/\D+/g, "");

        // if (unmaskedValue.length < 10) {
        //     inputPhone.classList.add(inputPhoneInvalidClass);
        //     isValid = false;
        // } else {
        //     inputPhone.classList.remove(inputPhoneInvalidClass);
        //     isValid = true;
        // }

        isValid = inputPhoneValidator.validate();

        return isValid;
    }*/

    function validatePassword(inputPassword, inputRepeatPassword) {
        let isValid;

        const inputInvalidClass = inputPassword.getAttribute("data-invalid-class");

        if((inputPassword.value.length > 5) && (inputPassword.value == inputRepeatPassword.value)) {
            //console
            if (inputPassword.classList.contains(inputInvalidClass)){
                inputPassword.classList.remove(inputInvalidClass);
            }
            if (inputRepeatPassword.classList.contains(inputInvalidClass)){
                inputRepeatPassword.classList.remove(inputInvalidClass);
            }
            isValid = true;

        } else {
            isValid = false;
            
            if(inputPassword.value != inputRepeatPassword.value) {
                inputRepeatPassword.classList.add(inputInvalidClass);
                //console.log("aaaaa", inputPassword.value != inputRepeatPassword.value);
                if(inputPassword.value.length < 6) {
                    inputPassword.classList.add(inputInvalidClass);
                } else {
                    if (inputPassword.classList.contains(inputInvalidClass)){
                        inputPassword.classList.remove(inputInvalidClass);    
                    }
                }
            } else {
                if (inputRepeatPassword.classList.contains(inputInvalidClass)){
                    inputRepeatPassword.classList.remove(inputInvalidClass);    
                }

                if(inputPassword.value.length < 6) {
                    inputPassword.classList.add(inputInvalidClass);
                } else {
                    if (inputPassword.classList.contains(inputInvalidClass)){
                        inputPassword.classList.remove(inputInvalidClass);    
                    }
                }
            }
        }

        return isValid;
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    /*function setTextInputsValid(inputsValidators) {
        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            inputsValidators[i].setValid();
        }
    }*/

    function validateForm(inputsValidators) {
        let isValid = true;

        const isTextInputsValid = validateTextInputs(inputsValidators);

        if (!isTextInputsValid) {
            isValid = isTextInputsValid;
        }

        const isInputPasswordValid = validatePassword(inputPassword, inputRepeatPassword);

        if (!isInputPasswordValid) {
            isValid = isInputPasswordValid;
        }

        return isValid;
    }
}