export default function InputFile(inputElement) {

    //let maxFileSize = 2000 * 1024 * 1024;
    //let maxFilesCount = 1;

    //let filesGroup = [];
    //let addressList =[];
    //let index = 0;

    let filesCurrent;

    let AddFileButton = inputElement;
    let AddFileWrapper = inputElement.closest(".js-label-file");
    let fileList = document.getElementById("order-services-list-file");
    let checkTechnicalTask = document.getElementById("checkbox-technical-task");
    //let fileList = AddFileButton.closest(".js-files-wrapper").querySelector(".js-list-files");
    //console.log("list", fileList);
    let itemPreviewTemplate = fileList.removeChild(fileList.getElementsByClassName("js-order-services-item")[0]);
    //console.log("itemPreviewTemplate1", itemPreviewTemplate);

    addChange();
    

    function addChange() {
        AddFileButton.addEventListener("change", houmFunction);
    }

    function houmFunction() {
        filesCurrent = this.files;
        //console.log("list", filesCurrent);

        //counterFile(filesCurrent);
        //inspectorARR();
        inspectorHTML();
    }

    /*function counterFile(filesGroupCurrent) {
        let filesGroupLength = filesGroup.length;

        for (let counter = 0; counter < filesGroupCurrent.length; counter++) {
            filesGroup[filesGroupLength + counter] = filesGroupCurrent[counter];
            addressList[filesGroupLength + counter] = index;

            index++;
        }
    }

    function inspectorARR() {
        let fullSize = 0;

        for (let counter = 0; counter < filesGroup.length; counter++) {
            fullSize += filesGroup[counter].size;

            if ((fullSize > maxFileSize) || (counter >= maxFilesCount)) {
                filesGroup.splice(counter);
                addressList.splice(counter);
                break;
            }
        }
    }*/

    function inspectorHTML() {
        //let currentElements = fileList.getElementsByClassName("js-order-services-item");
        addHTML();

        //console.log("filecurrent", filesCurrent);

        /*for (let counter = 0; counter < maxFilesCount; counter++) {

            addHTML(counter);
        }*/
    }

    function addHTML() {
        let currentFile = filesCurrent[0];
        //console.log("currentFile", currentFile);
        let itemPreview = itemPreviewTemplate.cloneNode(true);
        //console.log("itemPreview1", itemPreview);

        let text = currentFile.name;
        let sizeFile = (currentFile.size/1024).toFixed(1) + " KB";


        itemPreview.querySelector(".js-order-services-file-name").text = text;
        itemPreview.querySelector(".js-order-services-file-size").append(sizeFile);

        fileList.append(itemPreview);

        if(!AddFileWrapper.classList.contains("is-hidden")){
            AddFileWrapper.classList.add("is-hidden");
            checkTechnicalTask.setAttribute("disabled", true);
        }


        itemPreview.addEventListener("click", function() {
                //let currentCounter = addressList.indexOf(counter);

                itemPreview.remove();

                if(AddFileWrapper.classList.contains("is-hidden")){
                    AddFileWrapper.classList.remove("is-hidden");
                    checkTechnicalTask.removeAttribute("disabled");
                }
                //filesGroup.splice(currentCounter, currentCounter);
                //addressList.splice(currentCounter, currentCounter);
            }
        );
    }

}    