//import Datepicker from "js-datepicker";
//import datepicker from "@chenfengyuan/datepicker";

export default function DatetimeField(formElement) {
    //var $ = query;


    //let idStart = "#" + formElement.getAttribute("id-datepicker-start");
    //let idEnd =  "#" + formElement.getAttribute("id-datepicker-end");

    //$(idStart).datepicker();

    /*const picker = new Datepicker(idStart, {
        customDays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        customMonths: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        formatter: (input, date) => {
            const valueToLocale = date.toLocaleDateString();
            input.value = valueToLocale // => '1/1/2099'
        },
        onShow: function(instance) {
            instance.el.closest(".datetime-field").classList.add("is-focus");
        },
        onHide: function(instance) {
            instance.el.closest(".datetime-field").classList.remove("is-focus");
        },
        onSelect: function(instance) {
            if(!instance.el.closest(".datetime-field").classList.contains("is-field")){
                instance.el.closest(".datetime-field").classList.add("is-field");
            }
        }
    });
    const picker2 = new Datepicker(idEnd, {
        customDays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        customMonths: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        formatter: (input, date) => {
            const valueToLocale = date.toLocaleDateString();
            input.value = valueToLocale // => '1/1/2099'
        },
        onShow: function(instance) {
            instance.el.closest(".datetime-field").classList.add("is-focus");
        },
        onHide: function(instance) {
            instance.el.closest(".datetime-field").classList.remove("is-focus");
        },
        onSelect: function(instance) {
            if(!instance.el.closest(".datetime-field").classList.contains("is-field")){
                instance.el.closest(".datetime-field").classList.add("is-field");
            }
        }
    });

    let setDateBtns = document.getElementsByClassName("js-set-date-btn");
    let today = new Date();
    let tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));

    for (let i = 0, len = setDateBtns.length; i < len; i++) {
        let setDateBtn = setDateBtns[i];

        setDateBtn.addEventListener("click", function(){
            let dateValue = this.getAttribute("set-date");
            let datepicker = this.getAttribute("picker");

            if(dateValue === "today") {
                if(datepicker === "picker"){
                    picker.setDate(today, true);
                } else {
                    picker2.setDate(today, true);
                }
                
            } else {
                if(datepicker === "picker"){
                    picker.setDate(tomorrow, true);
                } else {
                    picker2.setDate(tomorrow, true);
                }
            }
        })
    }*/


    let inputTimeList = formElement.getElementsByClassName("datetime-field__input-time");

    for (let i = 0, len = inputTimeList.length; i < len; i++) {

        let inputTime = inputTimeList[i];
        let inputValue = inputTime.value;

        if(inputValue === ""){
            if(inputTime.classList.contains("is-value")){
                inputTime.classList.remove("is-value");
            }

        } else {
            if(!inputTime.classList.contains("is-value")){
                inputTime.classList.add("is-value");
            }
        }


        inputTime.addEventListener("change", function(){
            inputValue = this.value;
            //console.log("555", inputValue);
            if(inputValue === ""){
                //console.log("1111", inputValue);
                if(inputTime.classList.contains("is-value")){
                    inputTime.classList.remove("is-value");
                }

            } else {
                //console.log("222", inputValue);
                if(!inputTime.classList.contains("is-value")){
                    inputTime.classList.add("is-value");
                }
            }

        });


        inputTime.onfocus = function() {
            let dateTimeBlock = this.closest(".datetime-field");
            if(!dateTimeBlock.classList.contains("is-focus-time")){
                dateTimeBlock.classList.add("is-focus-time");
            }
        };

        inputTime.onblur = function() {
            let dateTimeBlock = this.closest(".datetime-field");
            if(dateTimeBlock.classList.contains("is-focus-time")){
                dateTimeBlock.classList.remove("is-focus-time");
            }
        }

    }

}