export default function Tabs(container) {

    let tabList = container.getElementsByClassName("js-tab");
    let contentList = document.getElementsByClassName("js-tab-content");

    for(let i = 0, len = tabList.length; i < len; i++) {
        let tab = tabList[i];

        tab.addEventListener("change", function(){
            let idTab = this.getAttribute("id");

            for(let i = 0, len = contentList.length; i < len; i++) {
                let content = contentList[i];
                let contentId = content.getAttribute("data-id");

                if(contentId === idTab) {
                    if(!content.classList.contains("is-show")){
                        content.classList.add("is-show");
                    }
                } else {
                    if(content.classList.contains("is-show")){
                        content.classList.remove("is-show");
                    }
                }
            }
        })
    }
}