export default function AccountOrderFixed(container) {

    const fixTask = container.querySelector(".js-order-preview-task-fixed");
    const fixSidebar = document.querySelector(".js-account-order-sidebar");
    const fixChat = document.querySelector(".js-chat");
    const fixList = fixChat.querySelector(".js-chat-performer-list");
    //const fixHeads = fixChat.querySelectorAll(".js-chat-performer-list");
    const heightFixList = fixList.clientHeight;
    
    const heightSidebar =  fixSidebar.clientHeight;
    const blockWrapper = container;
    const orderCardContainer = document.querySelector(".js-account-order-card-container");
    //let toTopFixChat = fixChat.getBoundingClientRect().bottom;//изменяется

    const fixChatTop = fixChat.getBoundingClientRect().top;
    console.log("start111111", fixChat,  fixChatTop);
    
    const itemsFixList = fixList.getElementsByClassName("js-tab");

    for (let i = 0, len = itemsFixList.length; i < len; i++) {
        let item = itemsFixList[i];
        //let scrollerStart = window.pageYOffset || document.documentElement.scrollTop;

        item.addEventListener("change", function (){
            //let scrollerEnd = window.pageYOffset || document.documentElement.scrollTop;
            //let delta = scrollerEnd - scrollerStart;

            //toTopFixChat = toTopFixChat - delta;
            //console.log("изменение таба", toTopFixChat, scrollerStart, scrollerEnd );
        });

    }

    window.addEventListener("scroll", function() {
        const scrolledY = window.pageYOffset || document.documentElement.scrollTop;
        

        console.log("scroll", document.documentElement.scrollTop);
        const fixChatTop = fixChat.getBoundingClientRect().top - 122;
        console.log("111111", fixChat,  fixChatTop);
        //let scrollToList = 0;
        //console.log("bottom", blockWrapperCoordinatesBottom);
        //console.log("tpdfdfg", hightTask, "sum", blockWrapperCoordinatesBottom - hightTask - 132);
        /*if(fixChatTop === 0){
            scrollToList = window.pageYOffset || document.documentElement.scrollTop;
        }*/
        if(fixChatTop <= 0) {
            console.log("finish");
            fixedChat();
            const fixChatBottom = fixChat.getBoundingClientRect().bottom;
            const toTopChat = fixChatBottom -heightFixList - 122;
            //const positionTopFixlist = toTopFixChat - heightFixList - 260; - пока что рабверсия
            //const positionTopFixlist = toTopFixChat + 122;
            const positionTopFixlist = orderCardContainer.clientHeight + 280 - heightFixList;
            console.log("dddd", toTopChat, "wwww", positionTopFixlist);

            if(toTopChat <= 0){
                fixList.style.position = "absolute";
                fixList.style.top = positionTopFixlist + "px";
            } else {
                fixList.style.position = "fixed";
                fixList.style.top = 120 + "px";
            }

        } else {
            notFixedChat();
        }

        if(scrolledY >= 200){
            fixedElement();
            const heightFixTask = fixTask.clientHeight;
            const blockWrapperCoordinatesBottom = blockWrapper.getBoundingClientRect().bottom;
            const toTopTaskWrapper = blockWrapperCoordinatesBottom - heightFixTask - 122;
            const positionTopFixTask = container.clientHeight + 300 - heightFixTask - 3;

            const toTopSidebarWrapper = blockWrapperCoordinatesBottom - heightSidebar - 122;
            const positionTopSidebar = container.clientHeight + 300 - heightSidebar;


            if(toTopTaskWrapper <= 0) {
                fixTask.style.position ="absolute";
                fixTask.style.top =  positionTopFixTask + "px";
            } else {
                fixTask.style.position ="fixed";
                fixTask.style.top = 120 + "px";
            }

            if(toTopSidebarWrapper <= 0) {
                fixSidebar.style.position ="absolute";
                fixSidebar.style.top =  positionTopSidebar + "px";
            } else {
                fixSidebar.style.position ="fixed";
                fixSidebar.style.top = 120 + "px";
            }

            //console.log("tpdfdfg", heightFixTask, "sum", toTopTaskWrapper, "height", positionTopFixTask);
            //console.log("tpdfdfg", heightSidebar, "sum", toTopSidebarWrapper, "height", positionTopSidebar);
        } else {
            notFixedElement();
        }
    });

    

    function fixedElement(){
        if(!fixTask.classList.contains("is-fixed")){
            fixTask.classList.add("is-fixed")
        }
        if(!fixSidebar.classList.contains("is-fixed")){
            fixSidebar.classList.add("is-fixed")
        }
    }



    function notFixedElement(){

        if(fixTask.classList.contains("is-fixed")){
            fixTask.classList.remove("is-fixed")
        }
        if(fixSidebar.classList.contains("is-fixed")){
            fixSidebar.classList.remove("is-fixed")
        }

        fixTask.style.position ="inherit";
        fixTask.style.top = "inherit";

        fixSidebar.style.position ="inherit";
        fixSidebar.style.top = "inherit";
    }

    function fixedChat(){
        if(!fixChat.classList.contains("is-fixed")){
            fixChat.classList.add("is-fixed")
        }
    }

    function notFixedChat(){

        if(fixChat.classList.contains("is-fixed")){
            fixChat.classList.remove("is-fixed")
        }

        fixList.style.position ="inherit";
        fixList.style.top = "inherit";

        //fixSidebar.style.position ="inherit";
        //fixSidebar.style.top = "inherit";
    }

}