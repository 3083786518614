import InputValidator from "scripts/partials/InputValidator.js";
//import Inputmask from "inputmask";

export default function EditAccount(form, query) {
    var $ = query;

    const formElement = form;
    formElement.setAttribute("novalidate", true);
    //const url = formElement.action;

    let inputFile = formElement.querySelector(".js-edit-account-input-files");
    let avatar = formElement.querySelector("#edit-account-avatar-preview");
    let labelFile = formElement.querySelector(".js-edit-account-label-file");

    const visibilityPassword = formElement.querySelector(".js-visibility-password-btn");
    const inputsPassword = formElement.getElementsByClassName("js-edit-account-form-input-password");
    const inputPassword = formElement.querySelector("#input-edit-account-password");
    const inputRepeatPassword = formElement.querySelector("#input-edit-account-repeat-password");
    const inputTel = formElement.querySelector("#input-edit-account-tel");
    const inputPatronymic = formElement.querySelector(".js-edit-account-form-input-not-required");

    const inputsValidating = formElement.querySelectorAll(".js-edit-account-form-input");
    const inputsValidators = [];
    //console.log(formElement, inputFile, avatar);

    inputPatronymic.addEventListener("change", function(){
        let valueInput = this.value;
        console.log("ff", valueInput);

        if(valueInput === ""){
            if(this.classList.contains("text-field__input_filled")){
                this.classList.remove("text-field__input_filled");
            }
        } else {
            if(!this.classList.contains("text-field__input_filled")){
                this.classList.add("text-field__input_filled");
            }
        }
    });

    visibilityPassword.addEventListener("click", function(){

        for (let i = 0, len = inputsPassword.length; i < len; i++) {
            const inputPassword = inputsPassword[i];

            if (inputPassword.getAttribute("type") === "password") {
                inputPassword.setAttribute("type", "text");
                this.classList.add("is-show");
            } else {
                inputPassword.setAttribute("type", "password");
                this.classList.remove("is-show");
            }
        }
    });

    for (let i = 0, len = inputsValidating.length; i < len; i++) {
        const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

        const inputValidator = new InputValidator({
            "domElement": inputsValidating[i],
            "requiredFlag": true,
            "errorClass": inputInvalidClass
        });
        inputsValidators.push(inputValidator);
    }

    formElement.addEventListener("submit", function(e) {
        const isValid = validateForm(inputsValidators);
        e.preventDefault();

        if (!isValid) {
            e.preventDefault();

        } else {
            //requestEditAccount();
			formElement.removeEventListener("submit", e, false);
			formElement.submit();

        }
    });

    /*function requestEditAccount(){
        $.ajax({
            type: "POST",
            url: url,
            dataType: "json",
            data: $(formElement).serialize(),
            success: function() {
                console.log("success");
            },
            error:  function(){
                console.log("fail");
            }
        });
    }*/

    inputFile.addEventListener("change", function(){
        if(!validateSize(this,1)){
            console.log("файл больше 1мб");
        } else {
            readUrl(this);
        }
    });

    function readUrl(input){
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                //$("#image").attr('src', e.target.result);
                let urlAvatar = "url(" + e.target.result + ")";
                $(avatar).css("backgroundImage", urlAvatar);

                if($(avatar).hasClass("no-avatar")){
                    $(avatar).removeClass("no-avatar");
                    $(labelFile).text("Сменить фото");
                }
            };
    
            reader.readAsDataURL(input.files[0]);
        }
    }

    function validateSize(fileInput,size) {
        var fileObj, oSize;
        fileObj = fileInput.files[0];
        /*if ( typeof ActiveXObject == "function" ) { // IE
            fileObj = (new ActiveXObject("Scripting.FileSystemObject")).getFile(fileInput.value);
        }else {
            fileObj = fileInput.files[0];
        }*/

        oSize = fileObj.size;
        if(oSize > size * 1024 * 1024){
            return false
        }
        return true;
    }

    function validatePassword(inputPassword, inputRepeatPassword) {

        const inputInvalidClass = inputPassword.getAttribute("data-invalid-class");

        let isValid;

        if(inputPassword.value.length === 0 && inputRepeatPassword.value.length === 0){
            
            if (inputPassword.classList.contains(inputInvalidClass)){
                inputPassword.classList.remove(inputInvalidClass);
            }
            if (inputRepeatPassword.classList.contains(inputInvalidClass)){
                inputRepeatPassword.classList.remove(inputInvalidClass);
                //console.log("совпали пароли1");
            }

            isValid = true;

        } else {
            if((inputPassword.value.length > 5) && (inputPassword.value === inputRepeatPassword.value)) {
                
                if (inputPassword.classList.contains(inputInvalidClass)){
                    inputPassword.classList.remove(inputInvalidClass);
                }
                if (inputRepeatPassword.classList.contains(inputInvalidClass)){
                    inputRepeatPassword.classList.remove(inputInvalidClass);
                    //console.log("совпали пароли2");
                }

                isValid = true;

            } else {
                isValid = false;
                
                if(inputPassword.value !== inputRepeatPassword.value) {
                    inputRepeatPassword.classList.add(inputInvalidClass);

                    if(inputPassword.value.length < 6) {
                        inputPassword.classList.add(inputInvalidClass);
                    } else {
                        if (inputPassword.classList.contains(inputInvalidClass)){
                            inputPassword.classList.remove(inputInvalidClass);
                        }
                    }
                } else {
                    if (inputRepeatPassword.classList.contains(inputInvalidClass)){
                        inputRepeatPassword.classList.remove(inputInvalidClass);
                    }

                    if(inputPassword.value.length < 6) {
                        inputPassword.classList.add(inputInvalidClass);
                    } else {
                        if (inputPassword.classList.contains(inputInvalidClass)){
                            inputPassword.classList.remove(inputInvalidClass);
                        }
                    }
                }
            }
        }

        return isValid;
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }



    function validateTelInput(inputTel) {
        let isValid;
        let input = inputTel;

        if(input.inputmask && input.inputmask.isComplete()){
            isValid = true;
            if(input.classList.contains("text-field__input_invalid")){
                input.classList.remove("text-field__input_invalid");
            }
        } else {
            isValid = false;
            if(!input.classList.contains("text-field__input_invalid")){
                input.classList.add("text-field__input_invalid");
            }
        }



        return isValid;
    }

    function validateForm(inputsValidators) {
        let isValid = true;

        const isTextInputsValid = validateTextInputs(inputsValidators);

        if (!isTextInputsValid) {
            isValid = isTextInputsValid;
        }

        const isInputTelValid = validateTelInput(inputTel);

        if (!isInputTelValid) {
            isValid = isInputTelValid;
        }

        const isInputPasswordValid = validatePassword(inputPassword, inputRepeatPassword);

        if (!isInputPasswordValid) {
            isValid = isInputPasswordValid;
        }

        return isValid;
    }


}    