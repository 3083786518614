import Choices from "choices.js";
import baron from "baron";

export default function Select(selectBlock) {
    const selectBaseClass = selectBlock.getAttribute("data-base-class");
    const selectElement = selectBlock.querySelector("select");


    new Choices(selectElement, {
        searchEnabled: false,
        shouldSort: false,
        placeholder: true,
        removeItemButton: true,
        placeholderValue: "Выберите из списка",
        classNames: {
            containerOuter: selectBaseClass + "__container",
            containerInner: selectBaseClass + "__inner",
            list: selectBaseClass + "__list",
            listSingle: selectBaseClass + "__label",
            listDropdown: selectBaseClass + "__dropdown",
            item: selectBaseClass + "__item",
            itemSelectable: selectBaseClass + "__item_selectable",
            itemChoice: selectBaseClass + "__dropdown-item",
            selectedState: selectBaseClass + "__dropdown-item_selected",
            button:  selectBaseClass + "__remove-item-button",
            placeholder: selectBaseClass + "__placeholder",
        },
        callbackOnCreateTemplates: function (template) {
            const classNames = this.config.classNames;
            //const itemSelectText = this.config.itemSelectText;

            return {
                dropdown: () => {
                    return template(`
                        <div class="${classNames.listDropdown} js-select-scroller" aria-expanded="false"></div>
                    `);
                },
                choice: (data, itemSelectText) => {
                    const selectedClass = data.selected ? classNames.selectedState : "";
                    // const ariaSelected = data.active ? "aria-selected=\"true\"" : "";
                    return template(`
                        <div
                            class="${classNames.item} ${classNames.itemChoice} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable} ${selectedClass}"
                            id="${data.elementId}"
                            data-select-text="${itemSelectText}"
                            data-choice
                            data-id="${data.id}"
                            data-value="${data.value}"
                            ${data.active ? "aria-selected=\"true\"" : ""}
                            ${data.disabled ? "aria-disabled=\"true\"" : ""}
                        >
                            ${data.label}
                        </div>
                    `);
                }
            };
        }
    });

    const selectDropdownBody = document.createElement("div");
    selectDropdownBody.className = selectBaseClass + "__dropdown-body";

    const baronScrollerContainer = selectBlock.querySelector(".js-select-scroller");
    const baronScroller = baronScrollerContainer.querySelector(".select__list");

    const selectScrollTrack = document.createElement("div");
    selectScrollTrack.className = selectBaseClass + "__scrolltrack";

    const selectScrollBar = document.createElement("div");
    selectScrollBar.className = selectBaseClass + "__scrollbar";

    selectScrollTrack.appendChild(selectScrollBar);
    baronScrollerContainer.appendChild(selectDropdownBody);
    selectDropdownBody.appendChild(baronScroller);
    baronScrollerContainer.appendChild(selectScrollTrack);

    const customScroller = baron({
        cssGuru: true,
        root: baronScrollerContainer,
        scroller: baronScroller,
        barOnCls: selectBaseClass + "__dropdown_scrolling",
        bar: selectScrollBar,
        direction: "v"
    });

    customScroller.controls({
        track: selectScrollTrack
    });

    baronScrollerContainer.style.display = "block";
    customScroller.update();
    baronScrollerContainer.style.display = "";

    /*const formElement =  document.getElementsByClassName("js-order-services-form")[0];
    

    if (formElement) {
        formElement.addEventListener("submit", function(){
            //let formValid = this.getAttribute("novalidate");


            //console.log("44444", selectChoices, selectChoices.getValue(true));
            //selectElement.selectedIndex = 0;
            //selectChoices.selectedIndex = -1;
            //selectChoices.setValue(["Выберите из списка"]);
            //let remove = selectBlock.getElementsByClassName("select__remove-item-button")[0];
            //console.log("ssdsssss", remove );
            //remove.click();
            console.log("fgfgg", selectChoice.getValue());
            if(selectChoice.getValue() !== "1"){
                //console.log("fgfgg", selectChoice.getValue());
                selectChoice.setChoices([],"1","Начать работу", true);
            }
        })
    }*/

    selectElement.addEventListener("change", function() {
        //console.log("select", this, this.selectedIndex);

        if(!this.closest(".js-select").classList.contains("is-field")){
            this.closest(".js-select").classList.add("is-field")
        }

        let activeValue;
        if(this.selectedIndex !== -1){
            activeValue = this.options[this.selectedIndex].value;
        }

        let hiddenInputBlock;
        let hiddenInput;
        let inputStart;
        let inputEnd;
        let inputBlockDateStart;
        let inputBlockDateEnd;
        
        if(this.closest(".js-order-services-select-block")){
            hiddenInputBlock = this.closest(".js-order-services-select-block").querySelector(".js-order-services-field-hidden");
            if(hiddenInputBlock){
                hiddenInput = hiddenInputBlock.querySelector(".text-field__input");
            }

        }

        if(document.getElementById("js-datetime-field-start")){
            inputBlockDateStart = document.getElementById("js-datetime-field-start");
            inputBlockDateEnd = document.getElementById("js-datetime-field-end");
            inputStart = inputBlockDateStart.querySelector("input");
            inputEnd = inputBlockDateEnd.querySelector("input");
        }
            
        

        //console.log("select", this, activeValue, hiddenInputBlock);


        switch(activeValue) {
            case "свой вариант": showInputAnotherOption(); break;
            case "1": hiddenInputDateEnd(); break;
            case "2": showInputsPeriod(); break;
            case "3": hiddenInputDateStart(); break;
            default: hiddenInputAnotherOption();
        }


        function showInputAnotherOption(){
            

            if(hiddenInputBlock && !hiddenInputBlock.classList.contains("is-show")){
                hiddenInputBlock.classList.add("is-show");
                hiddenInput.classList.add("js-order-services-form-input");
                hiddenInput.setAttribute("required", "required");
            }
        }

        function hiddenInputAnotherOption(){

            if(hiddenInputBlock && hiddenInputBlock.classList.contains("is-show")){
                hiddenInputBlock.classList.remove("is-show");
                hiddenInput.classList.add("js-order-services-form-input");
                hiddenInput.removeAttribute("required");
            }
        }

        function hiddenInputDateEnd(){
            if(inputBlockDateEnd.classList.contains("is-show")){
                inputBlockDateEnd.classList.remove("is-show");
                inputEnd.classList.remove("js-order-services-form-date");
                inputEnd.removeAttribute("required");
            }
            if(!inputBlockDateStart.classList.contains("is-not-label")){
                inputBlockDateStart.classList.add("is-not-label");
            }
            if(!inputBlockDateStart.classList.contains("is-show")){
                inputBlockDateStart.classList.add("is-show");
                inputStart.classList.add("js-order-services-form-date");
                inputStart.setAttribute("required", "required");
            }
        }

        function showInputsPeriod(){
            if(!inputBlockDateEnd.classList.contains("is-show")){
                inputBlockDateEnd.classList.add("is-show");
                inputEnd.classList.add("js-order-services-form-date");
                inputEnd.setAttribute("required", "required");
            }
            if(!inputBlockDateStart.classList.contains("is-show")){
                inputBlockDateStart.classList.add("is-show");
                inputStart.classList.add("js-order-services-form-date");
                inputStart.setAttribute("required", "required");

            }
            if(inputBlockDateStart.classList.contains("is-not-label")){
                inputBlockDateStart.classList.remove("is-not-label");
            }
            if(inputBlockDateEnd.classList.contains("is-not-label")){
                inputBlockDateEnd.classList.remove("is-not-label");
            }
        }

        function hiddenInputDateStart(){
            if(inputBlockDateStart.classList.contains("is-show")){
                inputBlockDateStart.classList.remove("is-show");
                inputStart.classList.remove("js-order-services-form-date");
                inputStart.removeAttribute("required");
            }
            if(!inputBlockDateEnd.classList.contains("is-not-label")){
                inputBlockDateEnd.classList.add("is-not-label");
            }
            if(!inputBlockDateEnd.classList.contains("is-show")){
                inputBlockDateEnd.classList.add("is-show");
                inputEnd.classList.add("js-order-services-form-date");
                inputEnd.setAttribute("required", "required");
            }
        }

    
    });
}