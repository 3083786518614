export default function DatetimeForm(form, requestStatus, vars) {
    const formElement = form;
    let dateList = formElement.getElementsByClassName("js-input-datetime-form-date");
    console.log("зашли", formElement);


    formElement.addEventListener("submit", function(e) {

        const isValid = validateForm(dateList);

        if (!isValid) {
            e.preventDefault();
            console.log("валидация не прошла");

        } else {

            requestStatus(form, vars);
            e.preventDefault();
            console.log("валидация успешна");

        }

    });

    function validateForm(dateList){

        let isValid = true;

        for (let i = 0, len = dateList.length; i < len; i++) {
            const isFormElementValid = searchValueDate(dateList[i]);

            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    function searchValueDate(date) {
        let valueDate = date.value;
        let dateBlock = date.closest(".datetime-field");
        let isValid;

        //console.log("valueDate", valueDate);

        if(valueDate === "") {
            if(!dateBlock.classList.contains("is-invalid")){
                dateBlock.classList.add("is-invalid");
            }
            if(dateBlock.classList.contains("is-field")){
                dateBlock.classList.remove("is-field");
            }
            isValid = false;
        } else {
            if(dateBlock.classList.contains("is-invalid")){
                dateBlock.classList.remove("is-invalid");
            }
            if(!dateBlock.classList.contains("is-field")){
                dateBlock.classList.add("is-field");
            }
            isValid = true;
        }

        return isValid;
    }


}