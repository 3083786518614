import InputValidator from "scripts/partials/InputValidator.js";

export default function PriceForm(form, requestStatus, vars) {
    const formElement = form;

    const inputsValidators = [];
    console.log("зашли", formElement);


    formElement.addEventListener("submit", function(e) {
        let inputsValidating = formElement.querySelectorAll(".js-price-form-input");
        for (let i = 0, len = inputsValidating.length; i < len; i++) {
            const inputInvalidClass = inputsValidating[i].getAttribute("data-invalid-class");

            const inputValidator = new InputValidator({
                "domElement": inputsValidating[i],
                "requiredFlag": true,
                "errorClass": inputInvalidClass
            });
            inputsValidators.push(inputValidator);
        }

        const isValid = validateForm(inputsValidators);

        if (!isValid) {
            e.preventDefault();
            console.log("валидация не прошла");

        } else {

            requestStatus(form, vars);
            e.preventDefault();
            console.log("валидация успешна");

        }

    });

    function validateForm(inputsValidators){
        let isValid = true;

        const isTextInputsValid = validateTextInputs(inputsValidators);

        if (!isTextInputsValid) {
            isValid = isTextInputsValid;
        }

        return isValid;

    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }


}