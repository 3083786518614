export default function HeaderFixed(container, query) {
    var $ = query;

    let headerBlock = container;
    

    let visibleClass = "is-fixed";
    
    $(window).scroll(function() {
        if ($(this).scrollTop() > 40){
            $(headerBlock).addClass(visibleClass);
        }
        else{
            $(headerBlock).removeClass(visibleClass);
        }
    });
}